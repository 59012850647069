/*
// .mobile-search
*/
@import '../variables';
@import '../mixins/direction';
@import '../mixins/breakpoints';
@import '../mixins/scheme';


$local-height: 36px;


.mobile-search {}
.mobile-search__body {
    display: flex;
    position: relative;
    z-index: 0;
    width: 100%;
}
.mobile-search__input,
.mobile-search__button {
    background: transparent;
    border: none;
    font-family: inherit;
    color: inherit;

    &:focus {
        outline: none;
    }
}
.mobile-search__input {
    flex-grow: 1;
    font-size: 15px;
}
.mobile-search__field {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: -1;
    transition:
        color .2s,
        background .2s,
        border-color .2s;
}
.mobile-search__button {
    width: ($local-height + 2px);
    flex-shrink: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color .2s;
    fill: currentColor;
}


@include media-breakpoint-up(md) {
    .mobile-search__input {
        height: $local-height;
        padding: 0 12px;
    }
    .mobile-search__field {
        border-radius: 3px;
        border-width: 1px;
        border-style: solid;

        @include define-input-scheme($mobile-search-scheme, normal);
    }
    .mobile-search__button--close {
        display: none;
    }
    .mobile-search__input:hover ~ .mobile-search__field {
        @include define-input-scheme($mobile-search-scheme, hover);
    }
    .mobile-search__input:focus ~ .mobile-search__field {
        @include define-input-scheme($mobile-search-scheme, focus);
    }

    @each $state in (normal, focus, hover) {
        $selector: '&';

        @if ($state != normal) {
            $selector: '.mobile-search__input:' + $state + '~';
        }

        #{$selector} {
            $local-button-scheme: map-get-default-map($mobile-search-scheme, #{$state}-icon-scheme);

            .mobile-search__button--search {
                color: map_get($local-button-scheme, normal-font-color);
            }
            .mobile-search__button--search:hover {
                color: map_get($local-button-scheme, hover-font-color);
            }
        }
    }
}


@include media-breakpoint-down(sm) {
    .mobile-search__body {
        height: 100%;
    }
    .mobile-search__input {
        padding-top: 0;
        padding-bottom: 0;

        @include direction {
            #{$padding-inline-start}: 16px;
            #{$padding-inline-end}: 0;
        }
    }
    .mobile-search__button {
        width: 52px;
    }
    .mobile-search__button--close {
        border-width: 0;
        border-style: solid;

        @include direction {
            #{$border-inline-start}-width: 1px;
        }
    }

    @each $state in (normal, focus, hover) {
        $input-selector: '';
        $button-selector: '&';

        @if ($state != normal) {
            $input-selector: ':' + $state;
            $button-selector: '.mobile-search__input:' + $state + '~';
        }

        .mobile-search__input#{$input-selector} {
            color: map_get($mobile-search-overlay-scheme, #{$state}-font-color);

            &::placeholder {
                color: map_get($mobile-search-overlay-scheme, #{$state}-placeholder-color);
            }

            & ~ .mobile-search__field {
                background: map_get($mobile-search-overlay-scheme, #{$state}-bg-color);
            }

            & ~ .mobile-search__button--close {
                border-color: map_get($mobile-search-overlay-scheme, #{$state}-border-color);
            }
        }

        #{$button-selector} {
            $local-button-scheme: map-get-default-map($mobile-search-overlay-scheme, #{$state}-icon-scheme);

            .mobile-search__button {
                color: map_get($local-button-scheme, normal-font-color);
            }
            .mobile-search__button:hover {
                color: map_get($local-button-scheme, hover-font-color);
            }
        }
    }
}
