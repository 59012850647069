/*
// .card-table
*/
@import '../variables';
@import '../mixins/direction';


.card-table {
    font-size: 15px;

    a:hover {
        text-decoration: underline;
    }

    table {
        width: 100%;
        min-width: 500px;

        @include direction {
            text-align: $inline-start;
        }
    }
    thead th {
        border-bottom: 1px solid map_get($card-scheme, divider);
        text-transform: uppercase;
        font-size: 13px;
        padding: 10px 12px;
    }
    tbody td {
        padding: 12px 12px;
    }
    tbody tr + tr td {
        border-top: 1px solid map_get($card-scheme, divider);
    }
    tbody tr:hover > * {
        background-color: $table-row-hover-bg;
    }
    th, td {
        &:first-child {
            @include direction {
                #{$padding-inline-start}: 2rem;
            }
        }
        &:last-child {
            @include direction {
                #{$padding-inline-end}: 2rem;
            }
        }
    }
}
