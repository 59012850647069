/*
// .vehicle-form
*/
@import '../variables';
@import '../mixins/direction';
@import '../mixins/breakpoints';


$local-gutter: 12px;


.vehicle-form {
    display: flex;
    flex-wrap: wrap;
    margin: -($local-gutter / 2);

    .vehicle-form__item {
        width: calc(50% - #{$local-gutter});
        margin: ($local-gutter / 2);
    }
    .vehicle-form__item:last-child {
        width: 100%;
    }
}
.vehicle-form__divider {
    width: 100%;
    line-height: 1;
    font-size: 13px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    color: map_get($card-scheme, muted);
    margin: 12px ($local-gutter / 2);

    &:before,
    &:after {
        display: block;
        content: '';
        height: 1px;
        flex-grow: 1;
        background: map_get($card-scheme, divider);
    }
    &:before {
        @include direction {
            #{$margin-inline-end}: 6px;
        }
    }
    &:after {
        @include direction {
            #{$margin-inline-start}: 6px;
        }
    }
}


.vehicle-form--layout--account {
    .vehicle-form__item {
        width: calc(25% - #{$local-gutter});
    }

    @include media-breakpoint-down(sm) {
        .vehicle-form__item {
            width: calc(50% - #{$local-gutter});
        }
    }

    @media (max-width: 459px) {
        .vehicle-form__item {
            width: calc(100% - #{$local-gutter});
        }
    }
}


.vehicle-form--layout--modal {
    @media (max-width: 399px) {
        .vehicle-form__item {
            width: calc(100% - #{$local-gutter});
        }
    }
}
