/*
// .quickview
*/
@import '../variables';
@import '../mixins/direction';
@import '../mixins/breakpoints';
@import '../mixins/scheme';


.quickview {
    max-width: 800px;
}
.quickview__body {
    padding: 32px 32px 36px;
    display: flex;
    flex-direction: row;
}
.quickview__close {
    z-index: 2;
    position: absolute;
    padding: 16px;
    border: none;
    top: 0;
    border-radius: 0 2.5px 0 2.5px;
    background: transparent;
    fill: currentColor;
    transition:
        background .2s,
        color .2s;

    @include define-button-scheme($btn-muted-scheme);
    @include direction {
        #{$inset-inline-end}: 0;
    }

    svg {
        display: block;
    }

    &:active {
        transition-duration: 0s;
    }
    &:focus {
        outline: none;
    }
}
.quickview__gallery {
    width: 320px;
    flex-shrink: 0;

    @include direction {
        #{$margin-inline-end}: 28px;
    }
}
.quickview__product {
    flex-grow: 1;
}



.quickview__product-name {
    font-size: 24px;
    line-height: 28px;
    font-weight: $font-weight-bold;
    margin-top: -2px;
}
.quickview__product-rating {
    display: flex;
    margin-top: 6px;
}
.quickview__product-rating-title {
    font-size: 13px;
    line-height: 1;
    color: map_get($card-scheme, opposite-alt);

    @include direction {
        #{$margin-inline-start}: 8px;
    }
}
.quickview__product-description {
    margin-top: 10px;
    font-size: 15px;
    line-height: 22px;
}
.quickview__product-meta {
    $local-gutter: 6px;

    margin: 12px 0 0;

    table {
        display: block;
        margin: -($local-gutter / 2);
    }
    tbody {
        display: flex;
        flex-wrap: wrap;
    }
    tr,
    th,
    td {
        display: block;
    }
    tr {
        background: $quickview-meta-bg-color;
        border-radius: 1.5px;
        margin: ($local-gutter / 2);
        padding-top: 5px;
        padding-bottom: 5px;

        @include direction {
            #{$padding-inline-start}: 7px;
            #{$padding-inline-end}: 14px;
        }
    }
    th {
        font-size: 11px;
        font-weight: $font-weight-normal;
        color: $quickview-meta-title-color;
        line-height: 1;
    }
    td {
        font-size: 13px;
        line-height: 1;
        font-weight: $font-weight-medium;
    }
    a {
        color: inherit;
    }
}
.quickview__product-prices-stock {
    display: flex;
    align-items: center;
    margin: 12px 0 16px;
}
.quickview__product-price {
    font-size: 24px;
    font-weight: $font-weight-bold;
    letter-spacing: -.04em;
}
.quickview__product-stock {
    margin-bottom: 2px;

    @include direction {
        #{$margin-inline-start}: 12px;
    }
}
.quickview__product-form {
    margin-bottom: 16px;
}
.quickview__product-actions {
    display: flex;
    flex-wrap: wrap;
    margin: -4px;
}
.quickview__product-actions-item {
    margin: 4px;
}
.quickview__product-actions-item--quantity {
    width: 100px;
}
.quickview__see-details {
    $local-height: 52px;

    border-radius: 0 0 2.5px 2.5px;
    border-top: 1px solid map_get($card-scheme, divider);
    display: block;
    text-align: center;
    color: map_get($card-scheme, opposite-alt);
    font-size: 15px;
    height: $local-height;
    line-height: ($local-height - 2px);
    transition:
        background .15s,
        border-color .15s;

    &:hover {
        color: map_get($card-scheme, opposite-alt);
        background: $quickview-see-details-hover-bg-color;
    }
    &:active {
        background: $quickview-see-details-active-bg-color;
        transition-duration: 0s;
    }
}


@include media-breakpoint-down(md) {
    .quickview {
        max-width: 520px;
    }
    .quickview__body {
        flex-direction: column;
    }
    .quickview__gallery {
        width: 100%;
        margin-bottom: 28px;

        @include direction {
            #{$margin-inline-end}: 0;
        }
    }
}
@include media-breakpoint-down(xs) {
    .quickview {
        max-width: 100%;
    }
}
@media (max-width: 474px) {
    .quickview__body {
        padding: 20px 20px 24px;
    }
}
