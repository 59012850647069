// @import 'App/Resources/styles/config';
@import 'App/Resources/styles/style';

@keyframes shadow {
  from {
    border: 2px solid white;
    box-shadow: 0px 0px 4px 3px rgba(white, 0.5);
    filter: opacity(85%);
  }
  50% {
    border: 1px solid white;
    box-shadow: 0px 0px 3px 1px rgba(white, 0.5);
    filter: opacity(50%);
  }
  to {
    border: 2px solid white;
    box-shadow: 0px 0px 4px 3px rgba(white, 0.5);
    filter: opacity(85%);
  }
}

.scrollToTopButton {
  background-color: white;
  border-radius: 50%;
  border: 2px solid white;
  color: $theme-color;
  // box-shadow: 0px 0px 3px 1px rgba(white, 0.5);
  // filter: opacity(50%);
  // z-index: 9999;
  transition: 1s ease-in-out;
  animation: shadow 2s infinite ease-in-out;

  &:hover {
    filter: none;
    animation: none;
  }
}
