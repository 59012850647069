@import 'custom-variables';

$header-variant-name: mobile-two;

@if (mixin_exists(define-header-variables)) {
    @include define-header-variables($header-variant-name);
}



$mobile-header-scheme-based-on:            theme !default;
$mobile-indicator-counter-scheme-based-on: dark  !default;

// search classic
$mobile-search-scheme: (
    // normal
    normal-bg-color:     #fff,
    normal-border-color: #fff,
    // hover
    hover-bg-color:      #ebebeb,
    hover-border-color:  #ebebeb,
    // focus
    focus-border-color:  #fff,
) !default;



@import 'style.mobile';
