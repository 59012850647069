/*
// .product-form
*/
@import '../variables';


.product-form {}
.product-form__row {
    & + & {
        margin-top: 12px;
    }
}
.product-form__title {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: $font-weight-medium;
    color: $product-form-title-color;
    margin-bottom: 4px;
}
