$theme-color: #416494;

$theme-scheme: () !default;
$theme-scheme: map_merge(
  (
    main: $theme-color,
    muted: #cce3ff,
  ),
  $theme-scheme
);

@mixin define-header-variables($header-variant-name) {
  // ----------------------------------------
  // -- classic-variant-two
  // ----------------------------------------
  @if ($header-variant-name==classic-two) {
    // topbar
    $topbar-classic-scheme: (
      main: adjust-color($theme-color, $lightness: -10, $saturation: -10),
    ) !default !global;

    // logo
    $logo-scheme: (
      primary: rgba(#fff, 0.9),
      secondary: adjust-color($theme-color, $lightness: -25, $saturation: -25),
    ) !default !global;
  }

  // ----------------------------------------
  // -- classic-variant-four
  // ----------------------------------------
  @if ($header-variant-name==classic-four) {
    // logo
    $logo-scheme: (
      primary: rgba(#fff, 0.9),
      secondary: adjust-color($theme-color, $lightness: -25, $saturation: -25),
    ) !default !global;
  }

  // ----------------------------------------
  // -- mobile-variant-two
  // ----------------------------------------
  @if ($header-variant-name==mobile-two) {
    // logo
    $logo-scheme: (
      primary: rgba(#fff, 0.9),
      secondary: adjust-color($theme-color, $lightness: -25, $saturation: -25),
    ) !default !global;
  }
}
