/*
// .add-vehicle-modal
*/
@import '../variables';
@import '../mixins/direction';
@import '../mixins/scheme';


.add-vehicle-modal {}
.add-vehicle-modal__body {
    padding: 32px 32px 36px;
}
.add-vehicle-modal__close {
    z-index: 2;
    position: absolute;
    padding: 16px;
    border: none;
    top: 0;
    border-radius: 0 2.5px 0 2.5px;
    background: transparent;
    fill: currentColor;
    transition:
        background .2s,
        color .2s;

    @include define-button-scheme($btn-muted-scheme);
    @include direction {
        #{$inset-inline-end}: 0;
    }

    svg {
        display: block;
    }
    &:active {
        transition-duration: 0s;
    }
    &:focus {
        outline: none;
    }
}
.add-vehicle-modal__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;

    > * {
        @include direction {
            #{$margin-inline-end}: 12px;
        }
    }
}

@media (max-width: 479px) {
    .add-vehicle-modal__body {
        padding: 24px 24px 28px;
    }
}
