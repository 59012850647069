// // @import url("~bootstrap/dist/css/bootstrap.css");
// @import url('./theme/vendor/bootstrap/css/bootstrap.css');
// @import url('~owl.carousel/dist/assets/owl.carousel.css');
// // @import url('~owl.carousel/dist/assets/owl.theme.default.css');
// // @import '~owl-carousel/assets/owl.carousel.min.css';
// // @import '~photoswipe/photoswipe.css';
// // @import '~photoswipe/default-skin/default-skin.css';
// // @import '~select2/css/select2.min.css';
// @import url('./theme/style.css');
// @import url('./theme/style.header-classic-variant-two.css') (min-width: 1200px);
// @import url('./theme/style.mobile-header-variant-one.css') (max-width: 1199px);
// @import url('./override/style.header-classic-variant-two.scss') (min-width: 1200px);
// @import '~fontawesome/css/all.min.css';

// @import url("~bootstrap/dist/css/bootstrap.css");
// @import './scss/bootstrap-variables.scss';

a[disabled],
a:disabled {
  pointer-events: none;
  text-decoration: line-through;
  cursor: not-allowed;
}

.suggestions__product-image:before {
  border: none;
}

.social-links__item--vk a {
  background-color: #4a76a8;
  color: #fff !important;
}

.social-links__item--telegram a {
  background-color: #0088cc;
  color: #fff !important;
}

// $custom-control-indicator-checked-color: #416494;
$custom-control-indicator-checked-border-color: #3a4d68;
$custom-control-indicator-checked-bg: #416494;
$custom-control-indicator-checked-box-shadow: red; //#3a4d68;

// @import '../../Resources/scss/bootstrap';
@import '../../../scss/bootstrap';
@import url('../../../scss/style.header-classic-variant-two.scss')
(min-width: 1200px);
@import url('../../../scss/style.mobile-header-variant-two.scss')
(max-width: 1199px);
@import url('../../../scss/header/_suggestions.scss');
// @import url('~bootstrap/scss/bootstrap.scss');
@import '~owl.carousel/dist/assets/owl.carousel.css';
@import '~react-toastify/dist/ReactToastify.css';
@import '../../../scss/style';

@media (max-width: 1199px) {
  .mobile-header__search {
    max-width: inherit;
  }
}

.products-list[data-layout='table'] {
  .product-card,
  .product-card__info {
    align-items: flex-start;
  }
  // .product-card .product-card__info {
  //   width: 150px;
  // }
}

.cart-table__column {
  vertical-align: top;
  padding: 14px 10px;

  &.cart-table__column--reference,
  &.cart-table__column--delivery_time {
    width: auto;
  }
}

.site {
  overflow: initial;
}

.search__body {
  min-width: 400px;
}

// .account-nav__item a {
//   padding: 2px 1.5rem;
// }

// @media (min-width: 576px) {
//   .products-list[data-layout='table'] .product-card .product-card__prices {
//     width: 137px;
//   }
// }

.order-list {
  a {
    color: inherit;
    &:hover {
      color: inherit;
      text-decoration: underline;
    }
  }
  table {
    width: 100%;
    font-size: 15px;
  }
}
.order-list__header {
  td {
    text-transform: uppercase;
    color: #6c757d;
    font-size: 13px;
    font-weight: 500;
  }
  th {
    text-transform: uppercase;
    color: #6c757d;
    font-size: 13px;
    font-weight: 500;
  }
}
.order-list__footer {
  font-size: 20px;
  th {
    font-weight: 500;
  }
}
.order-list__column-image {
  width: 1px;
  .image {
    width: 40px;
  }
}
.order-list__column-product {
  line-height: 1.25;
}
.order-list__column-quantity {
  text-align: center;
}
.order-list__column-total {
  text-align: right;
}
.order-list__options {
  color: #6c757d;
  font-size: 13px;
  margin-top: 2px;
}
.order-list__options-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
.order-list__options-item {
  &:not(:last-child) {
    position: relative;
    padding-right: 19px;
    &:after {
      position: absolute;
      content: '';
      display: block;
      background: #ccc;
      width: 1px;
      height: 10px;
      top: calc(50% - 5px);
      transform: skewX(-20deg);
      right: 9px;
    }
  }
}
@media (min-width: 576px) {
  .order-list {
    td {
      padding: 4px 12px;
      &:first-child {
        padding-left: 1.5rem;
      }
      &:last-child {
        padding-right: 1.5rem;
      }
    }
    th {
      padding: 4px 12px;
      &:first-child {
        padding-left: 1.5rem;
      }
      &:last-child {
        padding-right: 1.5rem;
      }
    }
  }
  .order-list__header {
    td {
      border-bottom: 1px solid #ebebeb;
      padding: 10px 12px;
    }
    th {
      border-bottom: 1px solid #ebebeb;
      padding: 10px 12px;
    }
  }
  .order-list__products {
    td {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    th {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    tr {
      &:first-child {
        td {
          padding-top: 14px;
        }
        th {
          padding-top: 14px;
        }
      }
      &:last-child {
        td {
          padding-bottom: 14px;
        }
        th {
          padding-bottom: 14px;
        }
      }
    }
    .order-list__column-product {
      padding-left: 4px;
    }
  }
  .order-list__subtotals {
    td {
      padding-top: 3px;
      padding-bottom: 3px;
    }
    th {
      padding-top: 3px;
      padding-bottom: 3px;
    }
    tr {
      &:first-child {
        td {
          padding-top: 12px;
          border-top: 1px solid #ebebeb;
        }
        th {
          padding-top: 12px;
          border-top: 1px solid #ebebeb;
        }
      }
      &:last-child {
        td {
          padding-bottom: 12px;
        }
        th {
          padding-bottom: 12px;
        }
      }
    }
  }
  .order-list__footer {
    td {
      padding-top: 14px;
      padding-bottom: 14px;
      border-top: 1px solid #ebebeb;
    }
    th {
      padding-top: 14px;
      padding-bottom: 14px;
      border-top: 1px solid #ebebeb;
    }
  }
}
@media (max-width: 575.98px) {
  .order-list {
    table {
      display: block;
      padding: 0;
    }
    td {
      display: block;
      padding: 0;
    }
    th {
      display: block;
      padding: 0;
    }
    tr {
      display: block;
      padding: 0;
    }
  }
  .order-list__footer {
    display: block;
    padding-top: 12px;
    padding-bottom: 12px;
    border-top: 1px solid #ebebeb;
    tr {
      padding: 0 1rem;
      display: flex;
    }
    .order-list__column-label {
      flex-grow: 1;
    }
    .order-list__column-quantity {
      display: none;
    }
  }
  .order-list__header {
    display: block;
    tr {
      padding: 0 1rem;
      display: flex;
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #ebebeb;
    }
    .order-list__column-label {
      flex-grow: 1;
    }
    .order-list__column-quantity {
      display: none;
    }
  }
  .order-list__products {
    display: block;
    tr {
      padding: 0 1rem;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      display: flex;
      flex-wrap: wrap;
      &:not(:first-child) {
        border-top: 1px solid #ebebeb;
      }
    }
    .order-list__column-image {
      width: 40px;
      flex-shrink: 0;
    }
    .order-list__column-product {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: calc(100% - 40px);
      padding-left: 1rem;
    }
    .order-list__column-quantity {
      &:before {
        content: attr(data-title) ' ';
      }
      margin-top: 0.625rem;
      margin-bottom: -0.125rem;
    }
    .order-list__column-total {
      flex-grow: 1;
      margin-top: 0.625rem;
      margin-bottom: -0.125rem;
    }
  }
  .order-list__subtotals {
    display: block;
    padding-top: 12px;
    padding-bottom: 12px;
    border-top: 1px solid #ebebeb;
    tr {
      padding: 0 1rem;
      display: -ms-flexbox;
      display: flex;
    }
    .order-list__column-label {
      flex-grow: 1;
    }
    .order-list__column-quantity {
      display: none;
    }
    td {
      padding-top: 2px;
      padding-bottom: 2px;
    }
    th {
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }
}

.order-success__body {
  max-width: 690px;
  margin: 0 auto;
}
.order-success__header {
  padding: 60px 0 52px;
  text-align: center;
}
.order-success__icon {
  fill: #e52727;
  margin: 0 auto;
  display: block;
}
.order-success__title {
  margin-top: 24px;
  margin-bottom: 2px;
}
.order-success__subtitle {
  font-size: 19px;
  font-weight: 500;
}
.order-success__actions {
  margin-top: 20px;
}
.order-success__meta {
  padding: 16px 0;
  margin-bottom: 20px;
  text-align: center;
}
.order-success__meta-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}
.order-success__meta-item {
  display: flex;
  justify-content: center;
  flex-basis: 25%;
  line-height: 1.25;
  position: relative;
  padding: 2px 12px;
  flex-direction: column;
  &:not(:last-child):before {
    position: absolute;
    display: block;
    content: '';
    border-left: 2px dashed rgba(0, 0, 0, 0.1);
    width: 0;
    height: 100%;
    top: 0;
    right: -1px;
  }
}
.order-success__meta-title {
  display: block;
  font-size: 13px;
  color: #6c757d;
  margin-bottom: 4px;
}
.order-success__meta-value {
  display: block;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
}
.order-success__addresses {
  margin-left: -20px;
  display: flex;
}
.order-success__address {
  margin-left: 20px;
  margin-top: 20px;
  flex-basis: 0;
  flex-grow: 1;
}
@media (max-width: 767.98px) {
  .order-success__header {
    padding: 44px 0 40px;
  }
  .order-success__meta-list {
    flex-wrap: wrap;
  }
  .order-success__meta-item {
    flex-basis: 50%;
    &:nth-child(2n) {
      &:before {
        display: none;
      }
    }
    &:nth-child(n + 3) {
      margin-top: 16px;
    }
  }
  .order-success__addresses {
    display: block;
  }
}

.mobile-search__vehicle-picker {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  order: -1;
  background: #ffdf40;
  color: #262626;
  min-width: 44px;
  padding: 0 12px;
  white-space: nowrap;
  font-size: 15px;
  font-weight: 500;
  border-radius: 2.5px;
  border: none;
  transition: color 0.15s, background-color 0.15s;

  &:hover {
    background: #ffd226;
  }

  &:active {
    background: #ffca16;
  }

  &:focus {
    outline: none;
  }

  svg {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    fill: rgba(0, 0, 0, 0.75);

    + .mobile-search__vehicle-picker-label {
      margin-left: 8px;
    }
  }
}

@media (min-width: 768px) {
  .mobile-search__vehicle-picker {
    margin-right: 6px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .mobile-search__vehicle-picker-label {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .mobile-search__vehicle-picker {
    margin: 8px 0;
    margin-left: 8px;
  }
}

@media (max-width: 440px) {
  .mobile-search__vehicle-picker-label {
    display: none;
  }
}
